import React, {useState, useEffect} from 'react';
import { Helmet } from 'react-helmet';
import '../styles/index.css';
import styled from 'styled-components';
import Navbar from '../components/Navbar';
import Hero from '../components/Hero';
import HeroMobile from '../components/HeroMobile';
import Members from '../components/Members';
import PastPerformance from '../components/PastPerformance';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import { lang } from '../constants/language';

const StyledContainer = styled.div`
  position: relative;
`;

const StyledToggle = styled.button`
  display: block;
  position: fixed;
  z-index: 99;
  top: 6px;
  right: 50px;

  @media (min-width: 1024px) {
   display: none;
`;

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== 'undefined';

function Home() {
  useEffect(() => {
    document.title = '夏元增京劇研習社｜Opera Hsia';  
  }, []);

  let languageByUser;
  if (isBrowser) {
    languageByUser = localStorage.getItem('language') ?? lang;
  } else {
    languageByUser = lang;
  }

  const [language, setLanguage] = useState(languageByUser);

  useEffect(() => {
    if (isBrowser) {
      localStorage.setItem('language', language);  
    }
  }, [language]);

  const toggleLanguage = () => {
    if (language === 'EN') {
      setLanguage('TR');
      localStorage.setItem('language', 'TR');  
    } else if (language === 'TR') {
      setLanguage('EN');
      localStorage.setItem('language', 'EN');  
    }
  };

  const toggleCopy = language === 'EN' ? '中' : 'EN';
  return (
    <div className="application">
      <Helmet
        title='Opera Hsia | 夏元增京劇研習社'
        meta={[
          {
            name: `description`,
            content: '在溫哥華也能欣賞的精彩京劇演出 ｜ Professional Peking Opera Club in Vancouver',
          },
          {
            property: `og:title`,
            content: 'Opera Hsia | 夏元增京劇研習社'
          },
          {
            property: `og:description`,
            content: '在溫哥華也能欣賞的精彩京劇演出 ｜ Professional Peking Opera Club in Vancouver'
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:image`,
            content: 'https://opera-hsia.vercel.app/static/performancePastLong-ce3b650e2319f8e2fd15f12781f839e5.png'
          }
        ].concat([])}
      />
      <StyledContainer className="container is-fullhd">
        <Navbar lang={language} toggleLanguage={toggleLanguage} />
        <HeroMobile lang={language} />
        <Hero lang={language} />
        <Members lang={language} />
        <PastPerformance lang={language} />
        <Contact lang={language} />
        <Footer lang={language} />
        <StyledToggle className="button is-success" onClick={toggleLanguage}>{toggleCopy}</StyledToggle>
      </StyledContainer>
    </div>
  );
}

export default Home;