import React, { useState } from 'react';
import ReactCardFlip from 'react-card-flip';
import Card from './Card';

function FlipCard({ card }) {
  const [isFlipped, setIsFlipped] = useState(false);
  const handleClick = () => {
    setIsFlipped(!isFlipped);
  }

  return (
    <ReactCardFlip isFlipped={isFlipped} className="column is-one-quarter">
      <Card src={card?.imageLife} name={card?.name} title={card?.frontTitle} intro={card?.frontIntro} handleClick={handleClick}/>
      <Card src={card?.imagePerform} name={card?.name} title={card?.backTitle} intro={card?.backIntro} handleClick={handleClick}/>
    </ReactCardFlip>
  );
}
  
export default FlipCard;