import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from "react-scroll";
import { useMedia } from 'react-use';
import logoRound from '../images/logoRound.png';
import content from '../constants/content.json';

const StyledNav = styled.div`
  position: relative;
  background-color: #fafafa;
  @media (min-width: 1024px) {
    padding: 0 75px 0 12px;
  }
`;

const StyledLogoText = styled.div`
  padding-left: 10px;
  font-size: 20px;
  font-weight: bold;
`;

const StyledToggle = styled.button`
  display: none;
  @media (min-width: 1024px) {
    display: block;
    position: absolute;
    z-index: 99;
    top: 6px;
    right: 0;
  }
`;

const Navbar = ({ lang, toggleLanguage }) => {
  const toggleCopy = lang === "EN" ? "中" : "EN";
  const contentLang = content[lang];
  const [isBurgerActive, setIsBurgerActive] = useState(false);
  const isWide = useMedia('(min-width: 760px)');

  return (
    <StyledNav className="navbar container is-fullhd is-fixed-top" role="navigation" aria-label="main navigation">
      <StyledToggle className="button is-success" onClick={toggleLanguage}>{toggleCopy}</StyledToggle>
      <div className="navbar-brand">
        <div className="navbar-item" href="https://www.operahsia.ca/">
          <img src={logoRound} width="31px" alt="logo" href="https://www.operahsia.ca/" />
          <StyledLogoText href="https://www.operahsia.ca/">{contentLang.logoText}</StyledLogoText>
        </div>
        <div
          onClick={() => { setIsBurgerActive(!isBurgerActive) }}
          className={`navbar-burger burger ${isBurgerActive ? 'is-active' : ''}`}
          data-target="navbarList"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </div>
      </div>
      <div
        id="navbarList"
        className={`navbar-menu ${isBurgerActive ? 'is-active' : ''}`}
      >
        <div className="navbar-end">
          <Link className="navbar-item" activeClass="active" smooth spy to={isWide ? 'intro' : 'introMobile'} href="https://www.operahsia.ca/">
            {contentLang.navbar.intro}
          </Link>

          <Link className="navbar-item" activeClass="active" smooth spy to="members" href="https://www.operahsia.ca/">
            {contentLang.navbar.members}
          </Link>

          <Link className="navbar-item" activeClass="active" smooth spy to="past-performance" href="https://www.operahsia.ca/">
            {contentLang.navbar.performance}
          </Link>

          <Link className="navbar-item" activeClass="active" smooth spy to="contact" href="https://www.operahsia.ca/">
            {contentLang.navbar.contact}
          </Link>
        </div>
      </div>
    </StyledNav>
  );
}

export default Navbar;