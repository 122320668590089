import React from 'react';
import logoRound from '../images/logoRound.png';
import styled from 'styled-components';
import content from '../constants/content.json';

const StyledLogoText = styled.div`
  font-size: 17px;
  font-weight: bold;

  @media (min-width: 460px) {
    font-size: 25px;
  }
`;

const Footer = ({lang}) => {
  const contentLang = content[lang];
  return (
    <footer className="footer">
      <div className="content has-text-centered">
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", gap: "20px"}}>
          <img src={logoRound} width="60px" alt="logo" />
          <StyledLogoText>{contentLang.footerText}</StyledLogoText>
        </div>
      </div>
    </footer>
  );
}

export default Footer;