import React from 'react';
import styled from 'styled-components';
import performance2022 from '../images/hero/performance2022.png';
import hsia2 from '../images/hero/hsia2.png';
import lee from '../images/hero/lee.png';
import earlyActivitiesSquare from '../images/hero/earlyActivitiesSquare.png';
import performancePast2 from '../images/hero/performancePast2.png';
import performanceTwSquare from '../images/hero/performanceTwSquare.png';
import content from '../constants/content.json';

const StyledSection = styled.section`
  display: block;
  @media (min-width: 760px) {
    display: none;
  };
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 70px;
`;

const StyledWrapper = styled.div`
  margin: 0 auto;
  padding: 10px 8px;
`;

const StyledImageWrapper = styled.div`
  display: flex;
`;

const StyledImage = styled.img`
  margin: 0 auto;
  width: 300px;
  min-width: 250px;
`;

const StyledContent = styled.p`
  padding-top: 15px;
  padding-bottom: 10px;
  text-align: left;
  line-height: 1.5;
  font-size: 18px;
  &:first-letter {
    margin-left: 40px;
  }
`

const StyledContentVertical = styled.p`
  padding-bottom: 15px;
  text-align: left;
  line-height: 1.5;
  font-size: 18px;
  &:first-letter {
    margin-left: 40px;
  }
`

const StyledHeadPics = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
`;

const StyledPicComment = styled.p`
  padding-top: 5px;
  text-align: center;
  font-size: 16px;
`

const HeroMobile = ({lang}) => {
  const contentLang = content[lang];
  return (
    <StyledSection id="introMobile" className="hero">
      <StyledContainer>
        <div className="title">
          {contentLang.hero.title}
        </div>
          <StyledWrapper>
            <StyledHeadPics>
              <figure className="image is-128x128">
                <img className="is-rounded" src={hsia2} alt="Mr. Hsia" />
              </figure>
              <figure className="image is-128x128">
                <img className="is-rounded" src={lee} alt="Ms. Lee" />
              </figure>
            </StyledHeadPics>
            <StyledContent>
              {contentLang.hero.first.message}
            </StyledContent>
            <StyledImageWrapper>
              <StyledImage src={performance2022} alt="2022演出幕後" />
            </StyledImageWrapper>
            <StyledPicComment>{contentLang.hero.first.picComment}</StyledPicComment>
          </StyledWrapper>
          <StyledWrapper>
            <StyledContentVertical>
              {contentLang.hero.second.message}
            </StyledContentVertical>
            <StyledImageWrapper>
              <StyledImage src={performancePast2} alt="過往演出" />
            </StyledImageWrapper>
            <StyledPicComment>{contentLang.hero.second.picComment}</StyledPicComment>
          </StyledWrapper>
          <StyledWrapper>
            <StyledContentVertical>
            {contentLang.hero.third.message}
            </StyledContentVertical>
            <StyledImageWrapper>
              <StyledImage src={performanceTwSquare} alt="台灣演出" />
            </StyledImageWrapper>
            <StyledPicComment>{contentLang.hero.third.picComment}</StyledPicComment>
          </StyledWrapper>
          <StyledWrapper>
            <StyledContentVertical>
              {contentLang.hero.fourth.message}
            </StyledContentVertical>
            <StyledImageWrapper>
              <StyledImage src={earlyActivitiesSquare} alt="早期活動" />
            </StyledImageWrapper>
            <StyledPicComment>{contentLang.hero.fourth.picComment}</StyledPicComment>
          </StyledWrapper>
      </StyledContainer>
    </StyledSection>
  );
}

export default HeroMobile;