import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots } from '@fortawesome/free-solid-svg-icons';
import FlipCard from './FlipCards/FlipCard';
import { memberCards } from '../constants/memberCards';
import content from '../constants/content.json';

const StyledSection = styled.section`
  font-family: 'Roboto', sans-serif;
`;

const StyledGrids = styled.div`
  display: grid;
  column-gap: 15px;
  row-gap: 30px;

  @media (min-width: 660px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 900px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1220px) {
    grid-template-columns: repeat(4, 1fr); 
  }
`;

const StyledTitleText = styled.span`
  padding-left: 10px;
  text-transform: capitalize;
  color: #4d4448;
`; 

const Members = ({lang}) => {
  const contentLang = content[lang];
  return (
    <StyledSection id="members" className="section">
      <div className="title">
        <FontAwesomeIcon icon={faCommentDots} color="#f0aa8d" />
        <StyledTitleText>{contentLang.members.membersTitle}</StyledTitleText>
      </div>
      <StyledGrids>
        {memberCards(lang).map((card) => (
          <FlipCard key={card.id} card={card} />
        ))}
      </StyledGrids>
    </StyledSection>
  );
}

export default Members;