import React from 'react';
import Home from '../containers/Home';

function Index() {
  return (
    <Home />
  );
}

export default Index;
