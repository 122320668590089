import React from 'react';
import styled from 'styled-components';


const StyledCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  &::after {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath d='M120 256C120 286.9 94.93 312 64 312C33.07 312 8 286.9 8 256C8 225.1 33.07 200 64 200C94.93 200 120 225.1 120 256zM280 256C280 286.9 254.9 312 224 312C193.1 312 168 286.9 168 256C168 225.1 193.1 200 224 200C254.9 200 280 225.1 280 256zM328 256C328 225.1 353.1 200 384 200C414.9 200 440 225.1 440 256C440 286.9 414.9 312 384 312C353.1 312 328 286.9 328 256z'/%3E%3C/svg%3E");
    position: absolute;
    opacity: 0.3;
    width: 20px;
    bottom: 6px;
    right: 10px;
    transition: 0.5s;
  }

  &:hover {
    box-shadow: 8px 8px 8px #8f7c84;
  }

  @media (min-width: 450px) and (max-width: 659px) {
    flex-direction: row-reverse;
    padding-bottom: 10px;
    &::after {
      bottom: 2px;
      left: 50%;
    }
  }

  @media (min-width: 660px) {
    flex-direction: column;
    align-items: inherit;
    padding-bottom: 0;
    &::after {
      bottom: 2px;
      right: 10px;
    }
  }

  @media (min-width: 900px) {
    flex-direction: column;
    width: 290px;
    height: 540px;
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1220px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const StyledImage = styled.div`
  position: relative;
  min-width: 150px;
  transition: 0.5s;

  @media (max-width: 450px) {
    width: 100%; 
  }
`;

const StyledImageTitle = styled.p`
  margin-top: 5px;
  text-align: center;
  color: #595858;
`;

const StyledContent = styled.div`
  padding: 6px 17px;
`;

const StyledName = styled.p`
  font-size: 22px;
  font-weight: bold;
`;

const StyledText = styled.p`
  font-size: 16px;
`;

function Card({ src, name, title, intro, handleClick }) {
  return (
    <StyledCard className="card" onClick={handleClick}>
      <StyledImage className="card-image">
        <figure className="image is-square">
          <img src={src} alt="memberPhoto" />
        </figure>
        <StyledImageTitle className="subtitle is-6">{title}</StyledImageTitle>
      </StyledImage>
      <StyledContent>
        <StyledName>{name}</StyledName>
        <StyledText className="content">
          {intro}
        </StyledText>
      </StyledContent>
    </StyledCard>
  );
}
  
export default Card;