import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
import location from '../images/location.png';
import content from '../constants/content.json';

const StyledWrapper = styled.div`
  padding: 0 40px;
  font-family: 'Roboto', sans-serif;
`;

const Contact = ({lang}) => {
  const contentLang = content[lang];
  return (
    <section id="contact" className="hero is-danger is-medium">
      <StyledWrapper>
        <div className="hero-body columns">
          <div className="column">
            <p className="title is-3">
              <span>{contentLang.contactTitle}</span>
              <a href="https://twitter.com/info47557206" style={{borderBottom: "none"}} rel="noopener noreferrer" target="_blank">
                <FontAwesomeIcon icon={faTwitterSquare} size="sm" style={{marginLeft: "25px"}}/>
              </a>
            </p>
            <div className="block">
              <p className="subtitle is-5">{contentLang.contactMessage}</p>
            </div>
            <div className="block">
              <div className="block">
                <FontAwesomeIcon icon={faEnvelope} size="lg" />
                <a href="mailto:operahsia@gmail.com" style={{marginLeft: "7px", fontSize: "18px"}}>operahsia@gmail.com</a>
              </div>
              <div className="block">
                <FontAwesomeIcon icon={faLocationDot} size="lg" />
                <span style={{marginLeft: "9px", fontSize: "18px"}}>{contentLang.contactAddress}{' | '}2/F 537 Main St, Vancouver</span>
              </div>
            </div>
            </div>
            <figure className="image column" style={{display: "flex", alignItems: "center", margin: "0 auto", maxWidth: "450px"}}>
              <img src={location} alt="map" />
            </figure>
        </div>
      </StyledWrapper>
    </section>
  );
}

export default Contact;