import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhotoFilm } from '@fortawesome/free-solid-svg-icons';
import { performanceSummary } from '../constants/pastPerformance';
import content from '../constants/content.json';

const StyledSection = styled.section`
  padding: 50px 0;
  @media (min-width: 760px) {
    padding: 50px 80px;
  }
`;

const StyledTitleText = styled.span`
  padding-left: 10px;
  text-transform: capitalize;
  color: #4d4448;
`; 

const PastPerformance = ({lang}) => {
  const contentLang = content[lang];
  return (
    <StyledSection id="past-performance" className="section">
      <div className="title">
        <FontAwesomeIcon icon={faPhotoFilm} color="#f0aa8d" />
        <StyledTitleText>{contentLang.pastPerformance.performanceTitle}</StyledTitleText>
      </div>
      <Carousel autoPlay stopOnHover={true} infiniteLoop transitionTime={800}>
        {performanceSummary(lang).map((p) => {
          return (
            <div key={p.id}>
              <img src={p.imgSrc} alt="PerformancePic"/>
              <p className="legend">{p.legend}</p>
            </div>
          )
        })}
      </Carousel>
    </StyledSection>
  );
}

export default PastPerformance;