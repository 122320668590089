import yanghanlife from '../images/memberPhotosLife/yanghan.png';
import yanghan from '../images/memberPhotosOpera/yanghan.png';
import miao from '../images/memberPhotosOpera/miao.png';
import miaolife from '../images/memberPhotosLife/miao.png';
import xia from '../images/memberPhotosOpera/xia.png';
import xialife from '../images/memberPhotosLife/xia.png';
import lijieling from '../images/memberPhotosOpera/lijieling.png';
import lijielinglife from '../images/memberPhotosLife/lijieling.png';
import susan from '../images/memberPhotosOpera/susan.png';
import susanlife from '../images/memberPhotosLife/susan.png';
import dongming from '../images/memberPhotosOpera/dongming.png';
import dongminglife from '../images/memberPhotosLife/dongming.png';
import yuwei from '../images/memberPhotosOpera/yuwei.png';
import yuweilife from '../images/memberPhotosLife/yuwei.png';
import dashan from '../images/memberPhotosOpera/dashan.png';
import dashanlife from '../images/memberPhotosLife/dashan.png';
import liping from '../images/memberPhotosOpera/liping.png';
import lipinglife from '../images/memberPhotosLife/liping.png';
import jing from '../images/memberPhotosOpera/jing.png';
import jinglife from '../images/memberPhotosLife/jing.png';
import christine from '../images/memberPhotosOpera/christine.png';
import christinelife from '../images/memberPhotosLife/christine.png';
import linda from '../images/memberPhotosOpera/linda.png';
import lindalife from '../images/memberPhotosLife/linda.png';
import huang from '../images/memberPhotosOpera/huang.png';
import huanglife from '../images/memberPhotosLife/huang.png';
import xinglei from '../images/memberPhotosOpera/xinglei.png';
import xingleilife from '../images/memberPhotosLife/xinglei.png';
import chenxuan from '../images/memberPhotosOpera/chenxuan.png';
import chenxuanlife from '../images/memberPhotosLife/chenxuan.png';
import yantianyun from '../images/memberPhotosOpera/yantianyun.png';
import yantianyunlife from '../images/memberPhotosLife/yantianyun.png';
import pangguan from '../images/memberPhotosOpera/pangguan.png';
import pangguanlife from '../images/memberPhotosLife/pangguan.png';
import content from '../constants/content.json';

export const memberCards = (lang) => {
  const contentLang = content[lang];
  return [
    {
      id: '1',
      name: contentLang.members.hsia.name,
      imageLife: xialife,
      imagePerform: xia,
      frontTitle: contentLang.members.hsia.frontTitle,
      frontIntro: contentLang.members.hsia.frontIntro,
      backTitle: contentLang.members.hsia.backTitle,
      backIntro: contentLang.members.hsia.backIntro,
    },
    {
      id: '2',
      name: contentLang.members.lee.name,
      imageLife: lijielinglife,
      imagePerform: lijieling,
      frontTitle: contentLang.members.lee.frontTitle,
      frontIntro: contentLang.members.lee.frontIntro,
      backTitle: contentLang.members.lee.backTitle,
      backIntro: contentLang.members.lee.backIntro,
    },
    {
      id: '3',
      name: contentLang.members.miao.name,
      imageLife: miaolife,
      imagePerform: miao,
      frontTitle: contentLang.members.miao.frontTitle,
      frontIntro: contentLang.members.miao.frontIntro,
      backTitle: contentLang.members.miao.backTitle,
      backIntro: contentLang.members.miao.backIntro,
    },
    {
      id: '4',
      name: contentLang.members.zhangshaoxin.name,
      imageLife: susanlife,
      imagePerform: susan,
      frontTitle: contentLang.members.zhangshaoxin.frontTitle,
      frontIntro: contentLang.members.zhangshaoxin.frontIntro,
      backTitle: contentLang.members.zhangshaoxin.backTitle,
      backIntro: contentLang.members.zhangshaoxin.backIntro,
    },
    {
      id: '5',
      name: contentLang.members.dong.name,
      imageLife: dongminglife,
      imagePerform: dongming,
      frontTitle: contentLang.members.dong.frontTitle,
      frontIntro: contentLang.members.dong.frontIntro,
      backTitle: contentLang.members.dong.backTitle,
      backIntro: contentLang.members.dong.backIntro,
    },
    {
      id: '6',
      name: contentLang.members.zhao.name,
      imageLife: yuweilife,
      imagePerform: yuwei,
      frontTitle: contentLang.members.zhao.frontTitle,
      frontIntro: contentLang.members.zhao.frontIntro,
      backTitle: contentLang.members.zhao.backTitle,
      backIntro: contentLang.members.zhao.backIntro,
    },
    {
      id: '7',
      name: contentLang.members.zhangaolan.name,
      imageLife: dashanlife,
      imagePerform: dashan,
      frontTitle: contentLang.members.zhangaolan.frontTitle,
      frontIntro: contentLang.members.zhangaolan.frontIntro,
      backTitle: contentLang.members.zhangaolan.backTitle,
      backIntro: contentLang.members.zhangaolan.backIntro,
    },
    {
      id: '8',
      name: contentLang.members.maliping.name,
      imageLife: lipinglife,
      imagePerform: liping,
      frontTitle: contentLang.members.maliping.frontTitle,
      frontIntro: contentLang.members.maliping.frontIntro,
      backTitle: contentLang.members.maliping.backTitle,
      backIntro: contentLang.members.maliping.backIntro,
    },
    {
      id: '9',
      name: contentLang.members.majing.name,
      imageLife: jinglife,
      imagePerform: jing,
      frontTitle: contentLang.members.majing.frontTitle,
      frontIntro: contentLang.members.majing.frontIntro,
      backTitle: contentLang.members.majing.backTitle,
      backIntro: contentLang.members.majing.backIntro,
    },
    {
      id: '10',
      name: contentLang.members.xing.name,
      imageLife: christinelife,
      imagePerform: christine,
      frontTitle: contentLang.members.xing.frontTitle,
      frontIntro: contentLang.members.xing.frontIntro,
      backTitle: contentLang.members.xing.backTitle,
      backIntro: contentLang.members.xing.backIntro,
    },
    {
      id: '11',
      name: contentLang.members.leng.name,
      imageLife: lindalife,
      imagePerform: linda,
      frontTitle: contentLang.members.leng.frontTitle,
      frontIntro: contentLang.members.leng.frontIntro,
      backTitle: contentLang.members.leng.backTitle,
      backIntro: contentLang.members.leng.backIntro,
    },
    {
      id: '12',
      name: contentLang.members.huang.name,
      imageLife: huanglife,
      imagePerform: huang,
      frontTitle: contentLang.members.huang.frontTitle,
      frontIntro: contentLang.members.huang.frontIntro,
      backTitle: contentLang.members.huang.backTitle,
      backIntro: contentLang.members.huang.backIntro,
    },
    {
      id: '13',
      name: contentLang.members.chenxuan.name,
      imageLife: chenxuanlife,
      imagePerform: chenxuan,
      frontTitle: contentLang.members.chenxuan.frontTitle,
      frontIntro: contentLang.members.chenxuan.frontIntro,
      backTitle: contentLang.members.chenxuan.backTitle,
      backIntro: contentLang.members.chenxuan.backIntro,
    },
    {
      id: '14',
      name: contentLang.members.xinglei.name,
      imageLife: xingleilife,
      imagePerform: xinglei,
      frontTitle: contentLang.members.xinglei.frontTitle,
      frontIntro: contentLang.members.xinglei.frontIntro,
      backTitle: contentLang.members.xinglei.backTitle,
      backIntro: contentLang.members.xinglei.backIntro,
    },
    {
      id: '15',
      name: contentLang.members.yantianyun.name,
      imageLife: yantianyunlife,
      imagePerform: yantianyun,
      frontTitle: contentLang.members.yantianyun.frontTitle,
      frontIntro: contentLang.members.yantianyun.frontIntro,
      backTitle: contentLang.members.yantianyun.backTitle,
      backIntro: contentLang.members.yantianyun.backIntro,
    },
    {
      id: '16',
      name: contentLang.members.pangguan.name,
      imageLife: pangguanlife,
      imagePerform: pangguan,
      frontTitle: contentLang.members.pangguan.frontTitle,
      frontIntro: contentLang.members.pangguan.frontIntro,
      backTitle: contentLang.members.pangguan.backTitle,
      backIntro: contentLang.members.pangguan.backIntro,
    },
    {
      id: '17',
      name: contentLang.members.yang.name,
      imageLife: yanghanlife,
      imagePerform: yanghan,
      frontTitle: contentLang.members.yang.frontTitle,
      frontIntro: contentLang.members.yang.frontIntro,
      backTitle: contentLang.members.yang.backTitle,
      backIntro: contentLang.members.yang.backIntro,
    },
  ];
};
