import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import styled from 'styled-components';
import performance2022 from '../images/hero/performance2022.png';
import hsia2 from '../images/hero/hsia2.png';
import lee from '../images/hero/lee.png';
import earlyActivitiesLong from '../images/hero/earlyActivitiesLong.png';
import performancePastLong from '../images/hero/performancePastLong.png';
import performanceTwLong from '../images/hero/performanceTwLong.png';
import content from '../constants/content.json';

const StyledSection = styled.section`
  display: none;
  @media (min-width: 760px) {
    display: block;
    padding: 40px 0;
  };
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 70px;
`;

const StyledTitle = styled.div`
  text-transform: capitalize;
`;

const StyledCarouselUnitVertical = styled.div`
  padding: 0 10px;
  margin: 0 auto;

  @media (min-width: 760px) {
    padding: 27px;
  }

  @media (min-width: 1120px) {
    padding: 0 70px;
  }

  @media (min-width: 1350px) {
    height: 600px;
  }
`;

const StyledCarouselUnitHorizontal = styled.div`
  display: none;
  @media (min-width: 760px) {
    display: grid;
    grid-template-areas:
      "pic head"
      "pic disc"
      "comment .";
    column-gap: 20px;
    align-items: center;
    margin: 0 auto;
    padding: 0 20px;
    width: 100%;
  }

  @media (min-width: 1120px) {
    column-gap: 50px;
    padding: 0 50px;
  }
`;

const StyledImageWrapper = styled.div`
  max-width: 650px;
  grid-area: pic;
`;

const StyledImage = styled.img`
  display: block;
  min-width: 250px;
`;

const StyledContent = styled.div`
  grid-area: disc;
  padding-top: 15px;
  padding-bottom: 10px;
  text-align: left;
  line-height: 1.5;
  font-size: 18px;
  &:first-letter {
    margin-left: 40px;
  }

  @media (min-width: 1120px) {
    line-height: 2;
  }

  @media (min-width: 1480px) {
    margin-top: -40px;
  }
`

const StyledContentVertical = styled.p`
  padding-bottom: 15px;
  text-align: left;
  line-height: 1.5;
  font-size: 18px;
  &:first-letter {
    margin-left: 40px;
  }

  @media (min-width: 1120px) {
    line-height: 2;
  }
`

const ToggleContent = styled.p`
  display: none;
  @media (min-width: 760px) {
    display: block;
    &:first-letter {
      margin-left: 30px;
    }
  }
`;

const StyledHeadPics = styled.div`
  grid-area: head;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
`;

const StyledPicComment = styled.p`
  grid-area: comment;
  line-height: 2;
  font-size: 16px;
`

const StyledImagesWide = styled.div`
  display: none;

  @media (min-width: 760px) {
    display: block;
    margin: 0 auto;
    max-width: 800px;
  }

  @media (min-width: 960px) {
    max-width: 800px;
  }

  @media (min-width: 1350px) {
    max-width: 1000px;
  }
`;

const Hero = ({ lang }) => {
  const contentLang = content[lang];
  return (
    <StyledSection id="intro" className="hero">
      <StyledContainer>
        <StyledTitle className="title">
          {contentLang.hero.title}
        </StyledTitle>
        <div style={{width: "100%"}}>
          <Carousel
            showThumbs={false}
            renderIndicator={(onClickHandler, isSelected, index, label) => {
              const defStyle = { marginLeft: 20, color: "#f0aa8d", cursor: "pointer" };
              const style = isSelected
                ? { ...defStyle, fontSize: '18px', fontWeight: "bold" }
                : { ...defStyle };
              return (
                <span
                  style={style}
                  onClick={onClickHandler}
                  onKeyDown={onClickHandler}
                  value={index}
                  key={index}
                  role="button"
                  tabIndex={0}
                  aria-label={`${label} ${index + 1}`}
                >
                  {index + 1}
                </span>
              );
            }}
          >
          <StyledCarouselUnitHorizontal>
            <StyledImageWrapper>
              <StyledImage src={performance2022} alt="2022演出幕後" />
            </StyledImageWrapper>
            <StyledHeadPics>
              <figure className="image is-128x128">
                <img className="is-rounded" src={hsia2} alt="Mr. Hsia" />
              </figure>
              <figure className="image is-128x128">
                <img className="is-rounded" src={lee} alt="Ms. Lee" />
              </figure>
            </StyledHeadPics>
            <StyledContent>
              {contentLang.hero.first.message}
              <ToggleContent>{contentLang.hero.first.message2}</ToggleContent>
            </StyledContent>
            <StyledPicComment>{contentLang.hero.first.picComment}</StyledPicComment>
          </StyledCarouselUnitHorizontal>
          <StyledCarouselUnitVertical>
            <StyledContentVertical>
              {contentLang.hero.second.message}
            </StyledContentVertical>
            <StyledImagesWide>
              <img src={performancePastLong} alt="過往演出" />
            </StyledImagesWide>
            <StyledPicComment>{contentLang.hero.second.picComment}</StyledPicComment>
          </StyledCarouselUnitVertical>
          <StyledCarouselUnitVertical>
            <StyledContentVertical>
              {contentLang.hero.third.message}
            </StyledContentVertical>
            <StyledImagesWide>
              <img src={performanceTwLong} alt="台灣演出" />
            </StyledImagesWide>
            <StyledPicComment>{contentLang.hero.third.picComment}</StyledPicComment>
          </StyledCarouselUnitVertical>
          <StyledCarouselUnitVertical>
            <StyledContentVertical>
              {contentLang.hero.fourth.message}
            </StyledContentVertical>
            <StyledImagesWide>
              <img src={earlyActivitiesLong} alt="早期活動" />
            </StyledImagesWide>
            <StyledPicComment>{contentLang.hero.fourth.picComment}</StyledPicComment>
          </StyledCarouselUnitVertical>
        </Carousel>
        </div>
      </StyledContainer>
    </StyledSection>
  );
}

export default Hero;