import miaoyuhua2022 from '../images/performancePics/202205miaoyuhua.png';
import end2022 from '../images/performancePics/202205end.png';
import dashan2022 from '../images/performancePics/202205dashan.png';
import susan2022 from '../images/performancePics/202205susan.png';
import huangying2022 from '../images/performancePics/202205huangying.png';
import yanghan2022 from '../images/performancePics/202205yanghan.png';
import linda2022 from '../images/performancePics/202205linda.png';
import xing2022 from '../images/performancePics/202205xing.png';
import majing2022 from '../images/performancePics/202205majing.png';
import maliping2022 from '../images/performancePics/202205maliping.png';
import muguiying2019 from '../images/performancePics/2019muguiying.png';
import sanniang2012 from '../images/performancePics/2012sanniang.png';
import yangguifei2016 from '../images/performancePics/2016yangguifei.png';
import content from '../constants/content.json';

export const performanceSummary = (lang) => {
    const contentLang = content[lang];

    return (
        [
            {
                id: 0,
                imgSrc: miaoyuhua2022,
                legend: contentLang.pastPerformance.performanceLegendMiaoMeifei,
            },
            {
                id: 1,
                imgSrc: susan2022,
                legend: contentLang.pastPerformance.performanceLegendSusanPengyin,
            },
            {
                id: 2,
                imgSrc: dashan2022,
                legend: contentLang.pastPerformance.performanceLegendDashanYuzhoufeng,
            },
            {
                id: 3,
                imgSrc: majing2022,
                legend: contentLang.pastPerformance.performanceLegendMajingYuji,
            },
            {
                id: 4,
                imgSrc: maliping2022,
                legend: contentLang.pastPerformance.performanceLegendMalipingTiannv,
            },
            {
                id: 5,
                imgSrc: xing2022,
                legend: contentLang.pastPerformance.performanceLegendXingLianjinfeng,
            },
            {
                id: 6,
                imgSrc: linda2022,
                legend: contentLang.pastPerformance.performanceLegendLindaGuifei,
            },
            {
                id: 7,
                imgSrc: huangying2022,
                legend: contentLang.pastPerformance.performanceLegendHuangHorse,
            },
            {
                id: 8,
                imgSrc: yanghan2022,
                legend: contentLang.pastPerformance.performanceLegendHanKongcheng,
            },
            {
                id: 9,
                imgSrc: end2022,
                legend: contentLang.pastPerformance.performanceLegendAllLihuasong,
            },
            {
                id: 10,
                imgSrc: muguiying2019,
                legend: contentLang.pastPerformance.performanceLegendSusanMuguiying,
            },
            {
                id: 11,
                imgSrc: sanniang2012,
                legend: contentLang.pastPerformance.performanceLegendMiaoSanniang,
            },
            {
                id: 12,
                imgSrc: yangguifei2016,
                legend: contentLang.pastPerformance.performanceLegendDashanYangguifei,
            },
        ]
    )
}
